import * as React from "react"
import classnames from "classnames"
import * as styles from "./localGuide.module.scss"
import ReactMapboxGl, {
  GeoJSONLayer,
  Layer,
  Source,
  Image,
  Popup,
} from "react-mapbox-gl"

interface Item {
  image?: string
  type: string
  geometry: {
    coordinates: number[]
    type: string
  }
  properties: {
    title: string
    description: string
    top10: boolean
    eat: boolean
    drink: boolean
    practical: boolean
    work: boolean
    "marker-color": string
    "marker-size": string
    "marker-symbol": string
  }
}

import {
  drinkPlaces,
  eatPlaces,
  features,
  practicalPlaces,
  top10,
  workPlaces,
} from "./data/features"

import kingsCollege from "./img/kings_college.png"
import fitzbillies from "./img/fitzbillies.png"
import dropPin from "./img/DropPin.png"

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiZ2VuaWV0ZWFtIiwiYSI6ImNrZnh5YnQxMzI1MjAyeW16bTVwZHR1d2kifQ.1LRUjNged6ZiKn-iDbf3Dg",
})

const LocalGuide = (): JSX.Element | null => {
  const [viewport, setViewport] = React.useState({
    latitude: 52.204629,
    longitude: 0.118753,
    zoom: 13,
  })
  const [activePoint, setActivePoint] = React.useState<Record<
    string,
    unknown
  > | null>(null)

  const Item = ({ item }: { item?: Item }) =>
    item ? (
      <div key={item.properties.title} className={styles.top10Item}>
        {item.properties.top10 ? (
          <div className={styles.imageWrapper}>
            <img src={item?.image} className={styles.image} />
          </div>
        ) : null}
        <div
          className={classnames(
            styles.top10Content,
            item.properties.top10 ? styles.top10Borders : ""
          )}
        >
          <h6 className={styles.top10Heading}>{item?.properties.title}</h6>
          <p className={styles.top10Description}>
            {item?.properties.description}
          </p>
        </div>
      </div>
    ) : null

  const Top10 = () => (
    <div className={styles.top10}>
      <h2 className={styles.top10Title}>Top 10</h2>
      {top10.map((item) => (
        <Item key={item?.properties.title} item={item} />
      ))}
    </div>
  )

  const Others = () => (
    <div className={styles.others}>
      <h2 className={styles.top10Title}>Places to Drink</h2>
      {drinkPlaces.map((item) => (
        <Item key={item?.properties.title} item={item} />
      ))}
      <h2 className={styles.top10Title}>Places to Eat</h2>
      {eatPlaces.map((item) => (
        <Item key={item?.properties.title} item={item} />
      ))}
      <h2 className={styles.top10Title}>Practical Places</h2>
      {practicalPlaces.map((item) => (
        <Item key={item?.properties.title} item={item} />
      ))}
      <h2 className={styles.top10Title}>Places to Work</h2>
      {workPlaces.map((item) => (
        <Item key={item?.properties.title} item={item} />
      ))}
    </div>
  )

  if (Map) {
    return (
      <div className={styles.pageWrapper}>
        <Map
          containerStyle={{ width: "100%", height: "520px", maxHeight: "70vh" }}
          style="mapbox://styles/genieteam/ckfxyin2b0pk619ox47rjwyy1"
          center={[viewport.longitude, viewport.latitude]}
          zoom={[viewport.zoom]}
          onClick={() => setActivePoint(null)}
          onZoomEnd={(map, e) =>
            setViewport({
              longitude: map.getCenter().lng,
              latitude: map.getCenter().lat,
              zoom: map.getZoom(),
            })
          }
        >
          <GeoJSONLayer
            data={features}
            symbolOnClick={(e) => {
              setActivePoint(e.features[0])
              setViewport({
                ...viewport,
                longitude: e.lngLat.lng,
                latitude: e.lngLat.lat,
              })
            }}
            symbolOnMouseEnter={(e) => {
              e.target.getCanvas().style.cursor = "pointer"
            }}
            symbolOnMouseLeave={(e) => {
              e.target.getCanvas().style.cursor = "grab"
            }}
            symbolLayout={{
              "icon-allow-overlap": true,
              "icon-image": "featurepin",
              "icon-size": 1,
            }}
          />
          {activePoint ? (
            <Popup
              offset={[0, -15]}
              coordinates={activePoint.geometry.coordinates}
            >
              <div className={styles.popupContent}>
                <h6 className={styles.popupTitle}>
                  {activePoint.properties.title}
                </h6>
                <div className={styles.popupDescription}>
                  {activePoint.properties.description}
                </div>
              </div>
            </Popup>
          ) : null}
        </Map>
        <div className={styles.pageContainer}>
          <h1 className={styles.title}>Local's Guide to Cambridge</h1>
          <p className={styles.description}>
            Welcome to our local's guide to Cambridge. Across the Genie team
            we've spent more than 20 years as students in Cambridge. We've made
            a list of our favourite places to eat, drink, and work so you can
            get to know the city we love.
          </p>
          <Top10 />
          <Others />
        </div>
      </div>
    )
  }
  return null
}

export default LocalGuide
