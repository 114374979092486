import bouldBrothers from "../img/bouldBrothers@2x.jpg"
import butchAnnies from "../img/butchAnnies@2x.png"
import breadMeat from "../img/breadMeat@2x.jpg"
import cambridgeBewaffle from "../img/cambridgeBewaffle@2x.jpg"
import espressoLibrary from "../img/espressoLibrary@2x.jpg"
import jacksGelato from "../img/jacksGelato@2x.jpg"
import pintShot from "../img/pintShot@2x.jpg"
import steamAndGlory from "../img/steamAndGlory@2x.jpg"
import theFreePress from "../img/theFreePress@2x.jpg"
import theLockerCafe from "../img/theLockerCafe@2x.jpg"

export const features = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        coordinates: [0.118753, 52.204629],
        type: "Point",
      },
      properties: {
        title: "Aromi",
        description:
          "A small family-owned Sicilian bakery with stores across Cambridge. Famous for their pizzas, gelato, and everything pistacchio.",
        top10: false,
        eat: true,
        drink: true,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.137285, 52.20009],
        type: "Point",
      },
      properties: {
        title: "Bedouin",
        description:
          "Moroccan food and fun, non-alcoholic cocktails all packaged up in a Bedouin tent.",
        top10: false,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.118448, 52.204161],
        type: "Point",
      },
      properties: {
        title: "Bread & Meat",
        description:
          "The ultimate sandwich cafe in town, with a small menu of well-earned favourites alongside ever changing specials.",
        top10: true,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.119877, 52.206016],
        type: "Point",
      },
      properties: {
        title: "Butch Annie's",
        description:
          "Just a door in a wall, leading down to a basement of burgers, beer, and rock music. Their vegetarian options are particularly good!",
        top10: true,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.119284, 52.205711],
        type: "Point",
      },
      properties: {
        title: "Cambridge BeWaffle",
        description:
          "Authentic Belgian waffles prepared by the kindest Belgian you will meet. As a bonus, hot chocolate does not get better than this.",
        top10: true,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.137158, 52.204861],
        type: "Point",
      },
      properties: {
        title: "Eko Kitchen",
        description:
          "A family-run Nigerian restaurant serving up an authentic African taste.",
        top10: false,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.118293, 52.204141],
        type: "Point",
      },
      properties: {
        title: "Jack's Gelato",
        description:
          "Now with two locatons in Cambridge, there is double the choice in their daily-changing menus. Plus soft serve!",
        top10: true,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.130651, 52.215782],
        type: "Point",
      },
      properties: {
        title: "Polonia Club",
        description:
          "Cosy Polish restaurant serving dumplings, beetroot soup and hearty stews!",
        top10: false,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.124372, 52.213953],
        type: "Point",
      },
      properties: {
        title: "Restaurant 22",
        description:
          "One for the special occasions. Fine dining in an elegant setting serving contemporary British dishes.",
        top10: false,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.119372, 52.204393],
        type: "Point",
      },
      properties: {
        title: "Steak & Honour",
        description:
          "If you want a delicious, messy, no frills American burger, this is the place to go. Make sure to go for a side of fries, they are thin, crispy, and addictive!",
        top10: false,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.122836, 52.207463],
        type: "Point",
      },
      properties: {
        title: "Stem & Glory",
        description:
          "A small restaurant serving the highest quality vegatarian food, with an inventive menu and plenty of tipples to have alongside.",
        top10: true,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.133004, 52.216197],
        type: "Point",
      },
      properties: {
        title: "Stir Cafe",
        description:
          "Amazing brunch if you are feeling poached eggs, sourdough toast and lovely baked goods.",
        top10: false,
        eat: true,
        drink: true,
        practical: false,
        work: true,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.120099, 52.206108],
        type: "Point",
      },
      properties: {
        title: "The Taste of Cambridge",
        description:
          "Falafel food truck in Cambridge Market Square selling freshly-prepared, vegan wraps.",
        top10: false,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.123602, 52.207446],
        type: "Point",
      },
      properties: {
        title: "The Locker",
        description:
          "If you want brunch, this is the place to go. Huge portions that do not cost an arm and a leg in a family-run cafe. The chai lattes here are an added treat.",
        top10: true,
        eat: true,
        drink: true,
        practical: false,
        work: true,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.143548, 52.198068],
        type: "Point",
      },
      properties: {
        title: "Urban Larder",
        description:
          "The place to go for cheesy goodness between two slices of sourdough bread. It is a trek, so you might as well stay and work there a bit too.",
        top10: false,
        eat: true,
        drink: true,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.134517, 52.201064],
        type: "Point",
      },
      properties: {
        title: "Vanderlyle",
        description:
          "Working with farmers directly, Vanderlyle crafts all-vegetarian tasting menus that change daily!",
        top10: false,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.134577, 52.204928],
        type: "Point",
      },
      properties: {
        title: "Zhonghua Traditional Snacks",
        description:
          "The best dumplings you can find in Cambridge, prepared in a simple family-run restaurant. While you are there, see what soy milk is meant to taste like - it is home-made!",
        top10: false,
        eat: true,
        drink: false,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "addresspin",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.144083, 52.197694],
        type: "Point",
      },
      properties: {
        title: "196 Cocktail cafe",
        description:
          "Hole in the wall cocktail cafe, a bit of a trek but worth every drop you will drink.",
        top10: false,
        eat: false,
        drink: true,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "cafe",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.094135, 52.183316],
        type: "Point",
      },
      properties: {
        title: "The Blue Ball Inn",
        description:
          "One for the summer. Great village pub with lovely beer garden if you be bothered to cycle to!",
        top10: false,
        eat: true,
        drink: true,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "cafe",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.118717, 52.208704],
        type: "Point",
      },
      properties: {
        title: "Bould Brothers",
        description:
          "No question about it, coffee does not get much better than this. Whether you want small and cozy in their first location, or wide and spacious at their second, it has it all.",
        top10: true,
        eat: false,
        drink: true,
        practical: false,
        work: true,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "cafe",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.118099, 52.203979],
        type: "Point",
      },
      properties: {
        title: "The Eagle",
        description:
          "An iconic, historic pub where Watson and Crick announced they had discovered the secret of life..",
        top10: false,
        eat: true,
        drink: true,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "cafe",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.113921, 52.199748],
        type: "Point",
      },
      properties: {
        title: "The Granta",
        description:
          "You would be hard pressed to find a better beer garden than this! Lovely waterside pub.",
        top10: false,
        eat: true,
        drink: true,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "cafe",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.122011, 52.198482],
        type: "Point",
      },
      properties: {
        title: "Hot Numbers",
        description:
          "A fantastic spot for coffee, conveniently close to a few departments. Throughout the week, their other store hosts plenty of live music.",
        top10: false,
        eat: true,
        drink: true,
        practical: false,
        work: true,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "cafe",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.118149, 52.204554],
        type: "Point",
      },
      properties: {
        title: "Indigo Cafe",
        description:
          "Tiny. Quaint. And the best bagels in town. Not for a big group of people, but perfect for an intimate catch-up.",
        top10: false,
        eat: true,
        drink: true,
        practical: false,
        work: true,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "cafe",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.119105, 52.204267],
        type: "Point",
      },
      properties: {
        title: "The Pint Shop",
        description:
          "If you love craft beer, this is the place to go to. Huge selection of real ales to go with great chargrilled meat and runny scotch eggs.",
        top10: true,
        eat: true,
        drink: true,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "cafe",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.130448, 52.20484],
        type: "Point",
      },
      properties: {
        title: "The Free Press",
        description:
          "One of the best pubs in Cambridge. Intimate, dimly-lit atmosphere with a fireplace.",
        top10: true,
        eat: true,
        drink: true,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "cafe",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.118377, 52.206862],
        type: "Point",
      },
      properties: {
        title: "The Ivy",
        description:
          "Great place for birthdays and celebrations. Plenty of fancy cocktails for when you are feeling bougie.",
        top10: false,
        eat: true,
        drink: true,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "cafe",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.116125, 52.201583],
        type: "Point",
      },
      properties: {
        title: "The Mill",
        description: "A riverside pub that is just perfect in the summer.",
        top10: false,
        eat: true,
        drink: true,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "cafe",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.126446, 52.214404],
        type: "Point",
      },
      properties: {
        title: "Thirsty",
        description:
          "Natural wines and craft beers, this spot is great for a chilled night alongside a bite from the food truck of the week.",
        top10: false,
        eat: true,
        drink: true,
        practical: false,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "cafe",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.119488, 52.206896],
        type: "Point",
      },
      properties: {
        title: "Director's Hair",
        description: "A more upscale, but absolutely worth it hair salon.",
        top10: false,
        eat: false,
        drink: false,
        practical: true,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "shop",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.1213, 52.207228],
        type: "Point",
      },
      properties: {
        title: "Reed's Hair",
        description: "A stylish place to get your hair cut.",
        top10: false,
        eat: false,
        drink: false,
        practical: true,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "shop",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.120319, 52.206647],
        type: "Point",
      },
      properties: {
        title: "Ryman Stationery",
        description:
          "A one-stop shop for all of your stationery needs during term.",
        top10: false,
        eat: false,
        drink: false,
        practical: true,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "shop",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.126021, 52.200585],
        type: "Point",
      },
      properties: {
        title: "Thomas William's Gentlemen's Barber",
        description:
          "A fantastic barber for the guys, offering good conversation and fresh cuts!",
        top10: false,
        eat: false,
        drink: false,
        practical: true,
        work: false,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "shop",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.109916, 52.201498],
        type: "Point",
      },
      properties: {
        title: "The Criminology Library",
        description:
          "One of the nicest departmental libraries for sitting down and getting on with work.",
        top10: false,
        eat: false,
        drink: false,
        practical: false,
        work: true,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "building",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.13171, 52.203386],
        type: "Point",
      },
      properties: {
        title: "Espresso Library",
        description:
          "Lots of seating, huge windows with plenty of light, and a steady supply of delicious coffee and food.",
        top10: true,
        eat: false,
        drink: false,
        practical: false,
        work: true,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "building",
      },
    },
    {
      type: "Feature",
      geometry: {
        coordinates: [0.116401, 52.201143],
        type: "Point",
      },
      properties: {
        title: "The Grad Cafe",
        description:
          "If you want a bit more of a social setting, never forget the Graduate Cafe!",
        top10: false,
        eat: false,
        drink: true,
        practical: false,
        work: true,
        "marker-color": "#1087bf",
        "marker-size": "large",
        "marker-symbol": "building",
      },
    },
  ],
}

const findFeature = (title: [string, string]) => {
  const fea = features.features.find(
    (feature) => feature.properties.title === title[0]
  )
  if (fea) {
    return {
      ...fea,
      image: title[1],
    }
  }
}

const top10Titles: [string, string][] = [
  ["Bould Brothers", bouldBrothers],
  ["Espresso Library", espressoLibrary],
  ["Jack's Gelato", jacksGelato],
  ["Stem & Glory", steamAndGlory],
  ["The Pint Shop", pintShot],
  ["The Locker", theLockerCafe],
  ["Bread & Meat", breadMeat],
  ["Butch Annie's", butchAnnies],
  ["The Free Press", theFreePress],
  ["Cambridge BeWaffle", cambridgeBewaffle],
]

export const top10 = top10Titles.map((title) => findFeature(title))

export const drinkPlaces = features.features.filter(
  (feature) => feature.properties.drink && !feature.properties.top10
)
export const eatPlaces = features.features.filter(
  (feature) => feature.properties.eat && !feature.properties.top10
)
export const practicalPlaces = features.features.filter(
  (feature) => feature.properties.practical && !feature.properties.top10
)
export const workPlaces = features.features.filter(
  (feature) => feature.properties.work && !feature.properties.top10
)
