import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BasicPage from "../templates/BasicPage"
import LocalGuide from "../components/LocalGuide"

const CambridgeGuidePage = (): JSX.Element => (
  <Layout>
    <SEO
      title="Genie - Local's Guide to Cambridge"
      description="Welcome to our local's guide to Cambridge. Across the Genie team we've spent more than 20 years as students in Cambridge. We've made a list of our favourite places to eat, drink, and work so you can get to know the city we love."
    />
    <BasicPage>
      <LocalGuide />
    </BasicPage>
  </Layout>
)

export default CambridgeGuidePage
